// workoutTimes.js
let EXERCISE_TIME;
let BREAK_TIME;

// Define phase objects
const PHASES = {
    getReady: { time: 7 }, // Default countdown time for "Get Ready!"
    exercise: { time: EXERCISE_TIME },
    break: { time: BREAK_TIME }
};

// Expose the setter and the phases
export function setWorkoutTimes(exerciseTime, breakTime) {
    EXERCISE_TIME = exerciseTime;
    BREAK_TIME = breakTime;

    PHASES.exercise.time = EXERCISE_TIME;
    PHASES.break.time = BREAK_TIME;
}

export function getPhases() {
	return PHASES;
}